import React from "react"
import { graphql } from "gatsby"

import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import ArticleCard from "../components/ArticleCard/ArticleCard"
import Seo from "../components/Seo"

const PublicOutreach = ({ data: { allWpPublicOutreachEvent } }) => {
  const allPublicOutreach = allWpPublicOutreachEvent.nodes
  return (
    <React.Fragment>
      <Seo title={"Public Outreach"} />
      <Navbar />
      <main className="marginTopNavbar">
        <div className="main-container">
          <section className="latest-news">
            <h1 className="latest-news__title">Public Outreach</h1>
            <div className="latest-news__grid">
              {allPublicOutreach.map(publicOutreach => {
                const title = publicOutreach.PublicOutreachGQL.title
                const alt =
                  publicOutreach.PublicOutreachGQL.thumbnailImage.altText
                const image =
                  publicOutreach.PublicOutreachGQL.thumbnailImage.localFile
                    .childImageSharp.gatsbyImageData
                const date = publicOutreach.date
                const uri = publicOutreach.uri

                return (
                  <ArticleCard
                    key={uri}
                    title={title}
                    alt={alt}
                    image={image}
                    date={date}
                    uri={uri}
                  />
                )
              })}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export const publicOutreach = graphql`
  {
    allWpPublicOutreachEvent(sort: { fields: date, order: DESC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        PublicOutreachGQL {
          title
          thumbnailImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  }
`
export default PublicOutreach

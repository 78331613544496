import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

export default function ArticleCard(props) {
  return (
    <article itemScope itemType="http://schema.org/Article" className="card">
      <Link to={props.uri} itemProp="url">
        <GatsbyImage
          draggable="false"
          className="card__image"
          alt={props.alt}
          image={props.image}
        />
      </Link>

      <Link to={props.uri} itemProp="url">
        <h2 className="card__title" itemProp="headline">
          {parse(props.title)}
        </h2>
      </Link>

      <p className="card__date small-text">
        <time>{props.date}</time>
      </p>
    </article>
  )
}
